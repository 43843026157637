import Page from "../../Page";
import classes from "../OfferPage.module.scss";
import test from "../../../assets/img/liczenie.png"

const OfferUVPage = () => {
  return (
    <>
      <Page>
        <div className={classes["banner-page"]}>
            <div className={classes["banner-page__item"]}>
                <h2>Druk UV na powierzchniach płaskich</h2>
                <p style={{textAlign:"center"}}>Poniższe ceny dotyczą tylko wydruku UV, nie zawierają kosztu materiału, na którym drukujemy</p>
                <p style={{textAlign:"center"}}>Podane ceny są cenami netto, należy doliczyć 23% VAT</p>
                <p style={{textAlign:"center"}}>Minimalne zamówienie 1m<sup>2</sup></p>
            </div> 

            <div className={classes["banner-page__sell"]}>
                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Druk UV CMYK</p>
                    </div>
                    
                    <span>60 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Druk UV CMYK + White</p>
                    </div>
                    
                    <span>110 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Druk UV na szkle</p>
                        <p>CMYK + White + primer do szkła</p>
                    </div>
                    
                    <span>150 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>docinanie na formatki</p>
                        <p>- mniejsze niż 1m<sup>2</sup> ( np. pcv ub dibond)</p>
                    </div>
                    
                    <span>od 50 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item--large"]}>
                    <div>
                        <p>Druk UV trójwarstwowy</p>
                        <p>CMYK + WHITE + CMYK</p>
                        <p>nadruki na podświetlane lica kasetonów</p>
                    </div>
                    
                    <span>130 zł/m<sup>2</sup></span>
                </div>
                
            </div> 

            <div className={classes["banner-page__item"]} style={{marginBottom:"2rem"}}>
                <p>Przedstawiona oferta cenowa ma charakter informacyjny, nie stanowi oferty handlowej w rozumieniu Art.66 par.1 Kodeksu Cywilnego</p>
            </div> 

            <div className={classes["banner-page__item"]}>
                <h2>Na czym możemy wykonać wydruk UV?</h2>
                <p style={{marginBottom:"1rem"}}>Drukujemy na płasko, na materiałach własnych oraz wybranych materiałach powierzonych przez klienta.</p>
                <p style={{marginBottom:"1rem"}}>Materiały takie jak: szkło (np. panele do kuchni czy łazienki), pcv, hips, pcv kolorowe, dibond, plexa, sklejka, płyta MDF, PET, płyty aluminiowe, papier, karton, canvas, gotowe podobrazia.</p>
                <p>Rozmiar druku - 1250 mm x 2500 mm - maksymalne pole robocze druku UV na płasko.
                Wysokość materiały nie może przekraczać 40 mm</p>
            </div> 

            <div className={classes["banner-page__item"]}>
                <p>Cena poszczególnych medii do druku na zapytanie - biuro@reklamajg.pl</p>
            </div> 

        </div>
 
     
      </Page>
    </>
  );
};

export default OfferUVPage;

import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import ClassicMenuContext from "../context/classic-menu-bar-context";
import classes from "../ClassicMenuBar.module.scss";

const defaultClass = classes["menubar__menu-item"];
const activeClass = `${defaultClass} ${classes["menubar__menu-item--active"]}`;

const ClassicMenuBarMenuItem = (props) => {
  const menuContext = useContext(ClassicMenuContext);
  const [menuItemClass, setMenuItemClass] = useState(defaultClass);

  useEffect(() => {
    if (menuContext.activeItem === props.name) {
      setMenuItemClass(activeClass);
    } else {
      setMenuItemClass(defaultClass);
    }
  }, [menuContext.activeItem, props.name]);

  const itemClicked = (e) => {
    menuContext.setIsMenuOpen(false);
    menuContext.setActiveItem(props.name);
  };

  return (
    <NavLink className={menuItemClass} to={props.to} onClick={itemClicked}>
      {props.name}
    </NavLink>
  );
};

export default ClassicMenuBarMenuItem;

import { HashRouter, Route, Routes } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import HomePage from "./pages/home/HomePage";
import OfferPage from "./pages/offer/OfferPage";
import ScrollToTop from "./components/UI/ScrollToTop";
import ContactPage from "./pages/contact/ContactPage";
import OfferBanerPage from "./pages/offer/subs/OfferBanerPage";
import OfferPoliPage from "./pages/offer/subs/OfferPoliPage";
import OfferUVPage from "./pages/offer/subs/OfferUVPage";
import RodoPage from "./pages/rodo/RodoPage";

function App() {
  return (
    <>
      <HashRouter>
      <ScrollToTop/>
        <Routes>
          <Route exact path="/" element={<Layout />}>
            <Route index element={<HomePage />} />

            <Route path="oferta" element={<OfferPage />} />
            <Route path="oferta/banery" element={<OfferBanerPage />} />
            <Route path="oferta/eco-solvent-i-poligrafia" element={<OfferPoliPage />} />
            <Route path="oferta/druk_uv" element={<OfferUVPage />} />


            <Route path="rodo" element={<RodoPage />} />

            <Route path="kontakt" element={<ContactPage />} />
          </Route>
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;

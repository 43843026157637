import Page from "../Page";
import OfferGallery from "../../components/UI/OfferGallery/OfferGallery";

import baner from "./../../assets/img/baner.png";
import wiz from "./../../assets/img/wizytowki.png";
import uv from "./../../assets/img/uv.png";

import classes from "./OfferPage.module.scss";

const OfferPage = () => {
  const data = [
    {
      name: "Banery",
      link: "/oferta/banery",
      src: baner,
    },

    {
      name: "Druk eco-solventowy i poligrafia",
      link: "/oferta/eco-solvent-i-poligrafia",
      src: wiz,
    },

    {
      name: "Druk UV",
      link: "/oferta/druk_uv",
      src: uv,
    },
  ];

  return (
    <>
      <Page>
        <div className={classes["offer-page__text"]}>
          <h2>Oferta</h2>
          <p>
            Dobra reklama to siła napędowa Twojej firmy. Dzięki nam uzyskasz
            niebanalną i intrygującą reklamę, która wzbudzi emocje w każdym kto
            będzie ją oglądał. Dobrze dopasowana grafika to potężne narzędzie,
            wykorzystywane przy budowaniu marki, kreowaniu wizerunku firmy czy
            pozyskiwaniu nowych klientów. </p>
        </div>
        <OfferGallery data={data} />

        <div className={classes["offer-page__text1"]}>
          <p>
          Zapraszamy do zapoznania się z naszą ofertą, dołożymy wszelkich starań, by reklama, którą stworzymy była Waszym asem w rękawie, atutem i silnym narzędziem.
          </p>
        </div>
      </Page>
    </>
  );
};

export default OfferPage;

import Page from "../../Page";
import classes from "../OfferPage.module.scss";
import test from "../../../assets/img/liczenie.png"

const OfferBanerPage = () => {
  return (
    <>
      <Page>
        <div className={classes["banner-page"]}>
            <div className={classes["banner-page__item"]}>
                <h2>Druk wielkoformatowy solwentowy</h2>
                <p style={{textAlign:"center"}}>Podane ceny są cenami netto, należy doliczyć 23% VAT</p>
                <p style={{textAlign:"center"}}>Minimalne zamówienie 2m<sup>2</sup></p>
            </div> 

            <div className={classes["banner-page__sell"]}>
                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>baner laminowany</p>
                        <p>bez obróbki (sam druk)</p>
                    </div>
                    
                    <span>16 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>baner laminowany</p>
                        <p>cięcie "na ostro" + oczkowanie co około 50cm</p>
                    </div>
                    
                    <span>18 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>baner laminowany</p>
                        <p>zgrzewane boki i oczkowanie co około 50cm</p>
                    </div>
                    
                    <span>22 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>baner powlekany (wzmacniany)</p>
                        <p>zgrzewane boki i oczkowanie co około 50cm</p>
                    </div>
                    
                    <span>25 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>siatka mesh</p>
                        <p>zgrzewane boki i oczkowanie co około 50cm</p>
                    </div>
                    
                    <span>28 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>baner odblaskowy</p>
                        <p>zgrzewane boki i oczkowanie co 50cm</p>
                    </div>
                    
                    <span>55 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>baner blockout</p>
                        <p>szerokość: 3m</p>
                    </div>
                    
                    <span>40 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Oczkowanie co 30cm</p>
                    </div>
                    
                    <span>5 zł/m<sup>2</sup></span>
                </div>
            </div> 

            <div className={classes["banner-page__item"]} style={{marginBottom:"2rem"}}>
                <p>Przedstawiona oferta cenowa ma charakter informacyjny, nie stanowi oferty handlowej w rozumieniu Art.66 par.1 Kodeksu Cywilnego</p>
            </div> 

            
            <h2 style={{marginBottom:"-0.5rem"}}>Jak obliczyć odpowiednią liczbę m<sup>2</sup> do druku</h2>

            <div className={classes["banner-page__how"]}>
                <div className={classes["banner-page__how-container"]}>
                    <div className={classes["banner-page__how-item"]}>
                        <p>Maksymalna szerokość druku na banerach - 310cm</p>
                        <p>Powyżej 310cm szerokości banery są brytowane i zgrzewane</p>
                        <p>Jednostką rozliczeniową jest pełny 1m<sup>2</sup></p>
                        <p>Minimalna ilość zakupu to 2m<sup>2</sup></p>
                        <p>Każdy rozpoczęty m<sup>2</sup> jest liczony jak pełny m<sup>2</sup></p>
                        <p>Każdy baner którego wymiar jest mniejszy niż 100x100cm - liczony jest po cenie minimalnego zamówienia</p>
                    </div>

                    <div className={classes["banner-page__how-case"]}>
                        <div className={classes["banner-page__how-case-item"]}>
                            <p>Przykład 1 - PURPUROWE POLE</p>
                            <p>Wymiar zamawianego banera 290x150cm = Zapłać za 6m<sup>2</sup></p>
                        </div>

                        <div className={classes["banner-page__how-case-item"]}>
                            <p>Przykład 2 - NIEBIESKIE POLE</p>
                            <p>Wymiar zamawianego banera 170x92cm = Zapłać za 2m<sup>2</sup></p>
                        </div>

                        <div className={classes["banner-page__how-case-item"]}>
                            <p>Przykład 3 - ŻÓŁTE POLE</p>
                            <p>Wymiar zamawianego banera 650x88cm = Zapłać za 7m<sup>2</sup></p>
                        </div>
                    </div>
                </div>

                <img className={classes["banner-page__how-image"]} src={test} />
            </div> 



     
           
        </div>
 
     
      </Page>
    </>
  );
};

export default OfferBanerPage;

import Page from "../../Page";
import classes from "../OfferPage.module.scss";
import test from "../../../assets/img/liczenie.png"

const OfferPoliPage = () => {
  return (
    <>
      <Page>
        <div className={classes["banner-page"]}>
            <div className={classes["banner-page__item"]}>
                <h2>Druk Eco-solventowy</h2>
                <p style={{textAlign:"center"}} >Podane ceny są cenami netto, należy doliczyć 23% VAT</p>
            </div> 

            <div className={classes["banner-page__sell"]}>
                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Folia monomerowa - błysk</p>
                    </div>
                    
                    <span>30 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Laminat monomerowy - błysk</p>
                    </div>
                    
                    <span>18 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Folia polimerowa - błysk</p>
                    </div>
                    
                    <span>45 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Laminat polimerowy - błysk</p>
                    </div>
                    
                    <span>25 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Folia wylewana - błysk</p>
                    </div>
                    
                    <span>90 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Laminat wylewany - błysk</p>
                    </div>
                    
                    <span>60 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Folia odblaskowa Oralight (5200)</p>
                    </div>
                    
                    <span>75 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Folia OWV</p>
                    </div>
                    
                    <span>50 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Blockout pet do rollup'ów</p>
                    </div>
                    
                    <span>60 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Papier plakatowy satynowy 150g</p>
                    </div>
                    
                    <span>40 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Docinanie papieru plakatowego</p>
                        <p>do mniejszych formatów</p>
                    </div>
                    
                    <span>od 25 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Tapeta samoprzylepna </p>
                        <p>"Linen ADH"</p>
                    </div>
                    
                    <span>60 zł/m<sup>2</sup></span>
                </div>

                

                <div className={classes["banner-page__sell-item"]}>
                    <div>
                        <p>Plotowanie wydrukowanej folii</p>
                        <p>małe naklejki, stikery, etykiety</p>
                    </div>
                    
                    <span>30 zł/m<sup>2</sup></span>
                </div>

                <div className={classes["banner-page__sell-item--large"]}>
                    <div>
                        <p>Tapeta "Shell"</p>
                        <p>winylowa na podkładzie flizelinowym</p>
                        <p>klejenie na klej do tapet</p>
                    </div>
                    
                    <span>50 zł/m<sup>2</sup></span>
                </div>
            </div> 

            <div className={classes["banner-page__item"]} style={{marginBottom:"2rem"}}>
                <p>Przedstawiona oferta cenowa ma charakter informacyjny, nie stanowi oferty handlowej w rozumieniu Art.66 par.1 Kodeksu Cywilnego</p>
            </div> 

            <div className={classes["banner-page__item"]}>
                <h2>Poligrafia - szybki druk cyfrowy i offsetowy</h2>
                <p>W ofercie posiadamy:
                wizytówki, ulotki, plakaty, teczki firmowe, katalogi szyte i klejone, kalendarze wiszące oraz książkowe, notesy, papiery firmowe, pocztówki, etykiety, vouchery, zaproszenia itp. W sprawie wyceny prosimy o kontakt na adres biuro@reklamajg.pl</p>
            </div> 

            <div className={classes["banner-page__item"]} style={{marginBottom:"2rem"}}>
                <p>Opracowanie graficzne - od 100 zł netto </p>
                <p>Końcowa cena opracowania projektu uzależniona jest od nakładu pracy </p>
            </div> 

            <h2 style={{marginBottom:"-0.5rem"}}>Jak obliczyć odpowiednią liczbę m<sup>2</sup> do druku</h2>

            <div className={classes["banner-page__how"]}>
                <div className={classes["banner-page__how-container"]}>
                    <div className={classes["banner-page__how-item"]}>
                        <p>Maksymalna szerokość druku na banerach - 310cm</p>
                        <p>Powyżej 310cm szerokości banery są brytowane i zgrzewane</p>
                        <p>Jednostką rozliczeniową jest pełny 1m<sup>2</sup></p>
                        <p>Minimalna ilość zakupu to 2m<sup>2</sup></p>
                        <p>Każdy rozpoczęty m<sup>2</sup> jest liczony jak pełny m<sup>2</sup></p>
                        <p>Każdy baner którego wymiar jest mniejszy niż 100x100cm - liczony jest po cenie minimalnego zamówienia</p>
                    </div>

                    <div className={classes["banner-page__how-case"]}>
                        <div className={classes["banner-page__how-case-item"]}>
                            <p>Przykład 1 - PURPUROWE POLE</p>
                            <p>Wymiar zamawianego banera 290x150cm = Zapłać za 6m<sup>2</sup></p>
                        </div>

                        <div className={classes["banner-page__how-case-item"]}>
                            <p>Przykład 2 - NIEBIESKIE POLE</p>
                            <p>Wymiar zamawianego banera 170x92cm = Zapłać za 2m<sup>2</sup></p>
                        </div>

                        <div className={classes["banner-page__how-case-item"]}>
                            <p>Przykład 3 - ŻÓŁTE POLE</p>
                            <p>Wymiar zamawianego banera 650x88cm = Zapłać za 7m<sup>2</sup></p>
                        </div>
                    </div>
                </div>

                <img className={classes["banner-page__how-image"]} src={test} />
            </div> 



     
           
        </div>
 
     
      </Page>
    </>
  );
};

export default OfferPoliPage;

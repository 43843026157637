import { useContext, useEffect, useState } from "react";
import { menu_item } from "../properties/classic-menu-bar-prop";

import ClassicMenuContext from "../context/classic-menu-bar-context";
import ClassicMenuBarMenuItem from "./ClassicMenuBarMenuItem";

import classes from "../ClassicMenuBar.module.scss";

const defaultClass = classes.menubar__menu;
const activeClass = `${defaultClass} ${classes["menubar__menu--active"]}`;

const ClassicMenuBarMenu = () => {
  const { isMenuOpen } = useContext(ClassicMenuContext);
  const [menuClass, setMenuClass] = useState("");

  useEffect(() => {
    if (isMenuOpen) {
      setMenuClass(activeClass);
    } else {
      setMenuClass(defaultClass);
    }
  }, [isMenuOpen]);

  const render = menu_item.map((item, index) => (
    <ClassicMenuBarMenuItem key={index} name={item.name} to={item.link} />
  ));

  return (
    <div className={menuClass}>
      <nav className={classes["menubar__menu-box"]}>{render}</nav>
    </div>
  );
};

export default ClassicMenuBarMenu;

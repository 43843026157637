import { NavLink } from "react-router-dom";
import classes from "./OfferGallery.module.scss";

const OfferGallery = (props) => {
  const render = props.data.map((item, index) => (
    <div key={index} className={classes["offer-gallery__item"]}>
      <div
        className={classes["offer-gallery__item-photo"]}
        style={{ backgroundImage: `url(${item.src})` }}
      />
      <div className={classes["offer-gallery__item-box"]}>
        <div>{item.name}</div>
        <NavLink to={item.link}>Sprawdź</NavLink>
      </div>
    </div>
  ));

  return <div className={classes["offer-gallery"]}>{render}</div>;
};

export default OfferGallery;
